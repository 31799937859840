<template>
  <AView
    :value="value"
    :actions="actions"
    :active="lead.id === activeLeadId"
    :name="lead.name"
    ref="lead-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, lead })"
  >
    <template v-slot:content="{}">
      <MessageBoxVue class="pa-4 pb-0" @onMessage="onMessage"></MessageBoxVue>
      <v-slide-group class="mb-4 ma-0 pa-0" center-active show-arrows>
        <v-slide-item
          v-for="status in availableStatuses"
          :key="status.id"
          v-slot="{}"
        >
          <v-chip
            class="ma-2"
            @click.stop.prevent="() => onStatusSelected(status)"
            label
            :disabled="lead.status ? status.id === lead.status.id : true"
          >
            {{ status.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
      <LeadTimelineVue
        :timeline="history2"
        :value="lead"
        :permissions="value.permissions"
        @onProposalClick="onProposalClick"
        @onFileClick="onFileClick"
        @action="(action) => action.handler({ from: value, lead })"
      ></LeadTimelineVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import { LEAD_BUTTONS } from "../constants/actions.gh";
import LeadTimelineVue from "../../../../../components/wad/molecules/sales/LeadTimeline.vue";
import MessageBoxVue from "../../../../../components/wad/molecules/common/MessageBox.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    LeadTimelineVue,
    MessageBoxVue,
  },
  data() {
    return {
      lead: {},
      allowedStatuses: [
        "TAKEN_TO_WORK",
        "ACTIVE",
        "ARCHIVED",
        "NO_REPLY",
        "VERBAL_OK",
        "DOCUMENTS_PREPARATION",
        "COMPLETED",
      ],
    };
  },
  computed: {
    ...mapState("LeadStore", [
      "activeLeadId",
      "displayedLeads",
      "history",
      "statuses",
    ]),
    ...mapGetters("LeadStore", ["leadById", "historyById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          LEAD_BUTTONS.GENERAL_EDITOR_MAIN,
          LEAD_BUTTONS.SETTINGS_EDITOR_MAIN,
          LEAD_BUTTONS.FILES_EDITOR_MAIN,
          LEAD_BUTTONS.HISTORY_MAIN,
        ],
        this.value.permissions
      );
    },
    history2: function () {
      return this.historyById(this.value.relation.params.leadId) || [];
    },
    availableStatuses: function () {
      return (this.statuses ? this.statuses : []).filter((status) =>
        this.allowedStatuses.find((s) => s === status.code)
      );
    },
  },
  created() {
    this.$store.dispatch("LeadStore/GetLeadHistory", {
      id: this.value.relation.params.leadId,
    });

    this.lead = this.leadById(this.value.relation.params.leadId);

    // VERY VERY NICE!!!!!!!!!!!!!!!

    // this.$watch(
    //   `history.${this.value.relation.params.leadId}`,
    //   (newVal) => {
    //     console.log("NEWASSSSS: ", newVal);
    //   },
    //   {
    //     deep: true,
    //   }
    // );
  },

  methods: {
    onHover() {
      this.$store.commit(
        "LeadStore/setActiveId",
        this.value.relation.params.leadId
      );
    },
    onProposalClick(proposal) {
      this.$store.dispatch("ProposalsStore/openProposalAssistant", {
        from: this.value,
        lead: this.lead,
        proposal: proposal,
      });
    },
    onFileClick(document) {
      document.u_type = "documents";

      this.$store.dispatch("FileStore/openFileDetails", {
        from: this.value,
        file: document,
      });
    },
    onMessage(data) {
      console.log("message: ", data);

      this.$store.dispatch("LeadStore/AddLeadHistory", {
        id: this.value.relation.params.leadId,
        data,
      });
    },
    onStatusSelected(status) {
      this.$store.dispatch("LeadStore/AddLeadHistory", {
        id: this.value.relation.params.leadId,
        data: { status },
      });
    },
  },
  watch: {
    displayedLeads: {
      async handler(newVal) {
        if (newVal) {
          this.lead = this.leadById(this.value.relation.params.leadId);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>